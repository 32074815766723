import { Dispatch, SetStateAction } from 'react';
import { useQuery } from '@tanstack/react-query';
import { range } from 'lodash';
import AddIcon from '@mui/icons-material/Add';
import { Autocomplete, Button, Paper, Skeleton, Stack, TextField, Typography } from '@mui/material';
import { useDebouncedQuery } from '@qb/frontend/hooks/useDebouncedQuery';
import { spaQueries } from '@qb/httpRequest/spaQueries';
import { ContactAutocompleteResponse } from '@/shared/types/controllers/spa/SPASearchControllerTypes';
import { isString } from '@/shared/types/util/TypeGuards';
import { useTenantAlias } from '@/src/hooks/useTenantAlias';
const CHARACTER_THRESHOLD = 1;
type Contact = ContactAutocompleteResponse['contacts'][0];
type ContactAutocompleteProps = {
  selectedContact: Contact | null;
  setSelectedContact: Dispatch<SetStateAction<Contact | null>>;
  openAddNewCustomerDrawer: (initialEmail: string) => void;
};
export const ContactAutocomplete = ({
  selectedContact,
  setSelectedContact,
  openAddNewCustomerDrawer
}: ContactAutocompleteProps) => {
  const tenantAlias = useTenantAlias();
  const {
    query,
    setQuery,
    debouncedQuery
  } = useDebouncedQuery('');
  const enabled = debouncedQuery.trim().length >= CHARACTER_THRESHOLD;
  const {
    data: contacts = [],
    isLoading
  } = useQuery({
    ...spaQueries.search.contactAutocomplete({
      query: debouncedQuery
    }, tenantAlias),
    enabled,
    select: data => data.contacts
  });
  return <Autocomplete inputValue={query} onInputChange={(_event, newInputValue) => {
    if (newInputValue.trim().length === 0) {
      setSelectedContact(null);
    }
    setQuery(newInputValue);
  }} onChange={(_event, newValue) => {
    if (isContact(newValue)) {
      setSelectedContact(newValue);
    }
  }} value={selectedContact} isOptionEqualToValue={(option, value) => {
    return option?.id === value?.id;
  }} multiple={false} options={contacts} filterOptions={options => options} renderInput={params => <TextField {...params} label="Enter name, e-mail, company name" />} getOptionLabel={option => isString(option) ? option : getContactFullName(option)} noOptionsText={<NoOptions />} renderOption={(props, option) => {
    // @ts-expect-error - have to delete key from props because we can't spread it, but TS claims that it doesn't exist on props
    const {
      key,
      ...optionProps
    } = props;
    return <Stack justifyContent="center" direction="row" component="li" gap={2} key={key + option.email} px={2} py={1} {...optionProps}>
            <Typography variant="h6" sx={{
        flex: 1
      }} noWrap>
              {option.companyName}
            </Typography>
            <Typography variant="bodyMedium" sx={{
        flex: 1
      }} noWrap>
              {option.firstName} {option.lastName}
            </Typography>
            <Typography variant="bodyMedium" sx={{
        flex: 1
      }} noWrap>
              {option.email}
            </Typography>
          </Stack>;
  }} PaperComponent={({
    children
  }) => {
    if (!enabled) {
      return null;
    }
    return <Paper square sx={{
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0
    }}>
            {isLoading && enabled ? <LoadingSkeleton /> : <>{children}</>}
            <Button variant="text"
      // onMouseDown required because it precedes onBlur
      onMouseDown={() => {
        openAddNewCustomerDrawer(debouncedQuery);
      }} sx={{
        ml: 1,
        mb: 0.5
      }} startIcon={<AddIcon />}>
              Add new customer
            </Button>
          </Paper>;
  }} data-sentry-element="Autocomplete" data-sentry-component="ContactAutocomplete" data-sentry-source-file="ContactAutocomplete.tsx" />;
};
const NoOptions = () => <Typography variant="bodyMedium" data-sentry-element="Typography" data-sentry-component="NoOptions" data-sentry-source-file="ContactAutocomplete.tsx">
    We couldn&apos;t find matching customer in our database
  </Typography>;
const LoadingSkeleton = () => <>
    {range(3).map((_, i) => <Skeleton key={i} height={20} sx={{
    mx: 2,
    my: 1
  }} />)}
  </>;
const isContact = (contact: unknown): contact is Contact => {
  return typeof contact === 'object' && contact !== null && 'firstName' in contact && 'lastName' in contact;
};
const getContactFullName = (contact: Contact) => `${contact.firstName} ${contact.lastName}`;