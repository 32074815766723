import { PropsWithChildren, ReactNode } from 'react';
import { Box, Stack, SxProps, Typography } from '@mui/material';
const MAIN_CONTENT_PADDINGX_MOBILE = 4;
const MAIN_CONTENT_PADDINGX_DESKTOP = 8;
type StandardDrawerLayoutProps = PropsWithChildren<{
  title?: string;
  breadcrumbs?: ReactNode;
}>;
export const StandardDrawerLayout = ({
  title,
  breadcrumbs,
  children
}: StandardDrawerLayoutProps) => {
  const isTitleOrBreadcrumbs = title || breadcrumbs;
  return <Stack minHeight="100%" data-sentry-element="Stack" data-sentry-component="StandardDrawerLayout" data-sentry-source-file="StandardDrawerLayout.tsx">
      {isTitleOrBreadcrumbs ? <Box sx={{
      display: 'flex',
      flexDirection: {
        xs: 'column',
        lg: 'row'
      },
      alignItems: 'center',
      gap: 3,
      py: 3,
      pr: {
        xs: 10,
        sm: 12,
        lg: 8
      },
      mr: {
        xs: 0,
        lg: 4
      },
      pl: {
        xs: 4,
        sm: 8,
        lg: 0
      },
      ml: {
        xs: 0,
        lg: 8
      },
      borderBottom: title ? '2px solid' : 'none',
      borderColor: 'primary.12p'
    }}>
          {breadcrumbs && <Box sx={{
        ml: 'auto',
        flexShrink: 0,
        order: {
          lg: 1
        }
      }}>
              {breadcrumbs}
            </Box>}
          {title && <Box sx={{
        width: '100%',
        minHeight: 40,
        display: 'flex',
        alignItems: 'center'
      }}>
              <Typography variant="h3">{title}</Typography>
            </Box>}
        </Box> : null}
      <Stack sx={{
      height: '100%',
      pt: {
        xs: 3,
        lg: isTitleOrBreadcrumbs ? 3 : 15
      },
      px: {
        xs: MAIN_CONTENT_PADDINGX_MOBILE,
        sm: MAIN_CONTENT_PADDINGX_DESKTOP
      },
      flexGrow: 1
    }} data-sentry-element="Stack" data-sentry-source-file="StandardDrawerLayout.tsx">
        {children}
      </Stack>
    </Stack>;
};
type StandardDrawerEditAreaProps = PropsWithChildren<{
  bgcolor?: string;
  sx?: SxProps;
}>;
export const StandardDrawerEditArea = ({
  bgcolor = 'common.backgroundColor',
  sx,
  children
}: StandardDrawerEditAreaProps) => <Stack sx={{
  bgcolor,
  pt: 3,
  pb: 5,
  mx: {
    xs: -MAIN_CONTENT_PADDINGX_MOBILE,
    sm: -MAIN_CONTENT_PADDINGX_DESKTOP
  },
  px: {
    xs: MAIN_CONTENT_PADDINGX_MOBILE,
    sm: MAIN_CONTENT_PADDINGX_DESKTOP
  },
  ...sx
}} data-sentry-element="Stack" data-sentry-component="StandardDrawerEditArea" data-sentry-source-file="StandardDrawerLayout.tsx">
    {children}
  </Stack>;