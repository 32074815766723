'use client';

import { PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams, useRouter } from 'next/navigation';
import { Alert } from '@mui/material';
import { PageContainer } from '@/src/components/Common/PageContainer';
import { isAdminSelector } from '@/src/store/reducers/main';
import { LoginView } from '../Auth/LoginView/LoginView';
const AdminOnlyError = () => <PageContainer maxWidth="xxl" sx={{
  py: 4
}} data-sentry-element="PageContainer" data-sentry-component="AdminOnlyError" data-sentry-source-file="PrivateRoute.tsx">
    <Alert severity="error" data-sentry-element="Alert" data-sentry-source-file="PrivateRoute.tsx">
      Only company admins are authorized to access this page.
    </Alert>
  </PageContainer>;
type PrivateRouteProps = PropsWithChildren<{
  isAdminOnlyRoute?: boolean;
}>;
export const PrivateRoute = ({
  children,
  isAdminOnlyRoute = false
}: PrivateRouteProps) => {
  const router = useRouter();
  const queryParams = useSearchParams();
  const pendingUserToken = queryParams?.get('pendingUserToken');
  const {
    user,
    hasFetchedUser
  } = useSelector(({
    main
  }) => main);
  const isAdmin = useSelector(isAdminSelector);
  if (typeof window === 'undefined') {
    // We have no way of redirecting on the server, nor accessing the user information, better to just not render the component
    return null;
  }
  if (hasFetchedUser) {
    if (user) {
      // Admin only route
      if (isAdminOnlyRoute) {
        return isAdmin ? children : <AdminOnlyError />;
      }

      // Everything else
      return children;
    }
    if (pendingUserToken) {
      router.push(`/pendingUser/completeProfile?token=${pendingUserToken}`);
      return null;
    }
    return <LoginView viewType="page" />;
  }
  return null;
};