import { Card, CardContent, CardHeader } from '@mui/material';
type InfoCardProps = {
  title: string;
  children: React.ReactNode;
};
export const InfoCard = ({
  title,
  children
}: InfoCardProps) => <Card sx={{
  height: '100%'
}} data-sentry-element="Card" data-sentry-component="InfoCard" data-sentry-source-file="InfoCard.tsx">
    <CardHeader title={title} sx={{
    pb: 1
  }} data-sentry-element="CardHeader" data-sentry-source-file="InfoCard.tsx" />
    <CardContent sx={{
    '&.MuiCardContent-root': {
      py: 1
    }
  }} data-sentry-element="CardContent" data-sentry-source-file="InfoCard.tsx">
      {children}
    </CardContent>
  </Card>;