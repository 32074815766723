import { useQuery } from '@tanstack/react-query';
import { Box, Skeleton, Stack } from '@mui/material';
import { formatAddressMultiLine } from '@qb/frontend/utils/formatAddress';
import { spaQueries } from '@qb/httpRequest/spaQueries';
import { ContactAutocompleteResponse } from '@/shared/types/controllers/spa/SPASearchControllerTypes';
import { CustomerEnrichmentLine } from '@/src/components/CustomerEnrichment/components/CustomerEnrichmentLine';
import { InfoCard } from '@/src/components/CustomerEnrichment/components/InfoCard';
import { useTenantAlias } from '@/src/hooks/useTenantAlias';
type CustomerDetailsProps = {
  contact: ContactAutocompleteResponse['contacts'][0];
};
export const CustomerInfoCard = ({
  contact
}: CustomerDetailsProps) => {
  const tenantAlias = useTenantAlias();
  const {
    data: enrichedContact,
    isLoading
  } = useQuery({
    ...spaQueries.contact.findOne(tenantAlias, contact.id),
    enabled: Boolean(contact)
  });
  return <Box data-sentry-element="Box" data-sentry-component="CustomerInfoCard" data-sentry-source-file="CustomerInfoCard.tsx">
      <InfoCard title="Customer details" data-sentry-element="InfoCard" data-sentry-source-file="CustomerInfoCard.tsx">
        <Stack direction="row" spacing={6} data-sentry-element="Stack" data-sentry-source-file="CustomerInfoCard.tsx">
          <Box data-sentry-element="Box" data-sentry-source-file="CustomerInfoCard.tsx">
            <CustomerEnrichmentLine source="user" text={`${contact.firstName} ${contact.lastName}`} data-sentry-element="CustomerEnrichmentLine" data-sentry-source-file="CustomerInfoCard.tsx" />
            <CustomerEnrichmentLine source="user" text={contact.companyName} data-sentry-element="CustomerEnrichmentLine" data-sentry-source-file="CustomerInfoCard.tsx" />
          </Box>
          <Box data-sentry-element="Box" data-sentry-source-file="CustomerInfoCard.tsx">
            {isLoading ? <>
                <Skeleton width={140} />
                <Skeleton width={140} />
              </> : enrichedContact?.address && <CustomerEnrichmentLine source={enrichedContact.addressSource} text={formatAddressMultiLine({
            ...enrichedContact.address,
            countryCode: enrichedContact.address.country
          })} sx={{
            alignItems: 'flex-start'
          }} />}
          </Box>
          <Box data-sentry-element="Box" data-sentry-source-file="CustomerInfoCard.tsx">
            {isLoading ? <>
                <Skeleton width={140} />
                <Skeleton width={140} />
              </> : enrichedContact && <>
                  <CustomerEnrichmentLine text={enrichedContact.jobTitle} source={enrichedContact.jobTitleSource} />
                  <CustomerEnrichmentLine text={enrichedContact.phone} source={enrichedContact.phoneSource} />
                  <CustomerEnrichmentLine text={enrichedContact.contact.email} source="user" />
                </>}
          </Box>
        </Stack>
      </InfoCard>
    </Box>;
};