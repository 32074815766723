import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Box, Button, Unstable_Grid2 as Grid } from '@mui/material';
import { LoadingButton } from '@qb/frontend/components/LoadingButton';
import constants from '@/shared/Constants';
import { ContactFindOneByEmailResponse } from '@/shared/types/controllers/spa/SPAContactControllerTypes';
import { CompanyDetailsForm } from '@/src/components/CreateNewCustomerDrawer/CompanyDetailsForm';
import { CustomerDetailsForm } from '@/src/components/CreateNewCustomerDrawer/CustomerDetailsForm';
const getMaxlengthMessageArgs = (max = constants.MAX_STRING_LENGTH) => ([max, `Must be ${max} characters or less`] as const);
const formSchema = z.object({
  firstName: z.string().trim().min(1, 'First name is required').max(...getMaxlengthMessageArgs()),
  lastName: z.string().trim().min(1, 'Last name is required').max(...getMaxlengthMessageArgs()),
  email: z.string().trim().email('Invalid email address').trim().max(...getMaxlengthMessageArgs()),
  phone: z.string().trim().max(...getMaxlengthMessageArgs()).optional(),
  companyID: z.string().trim(),
  companyName: z.string().trim().max(...getMaxlengthMessageArgs()),
  companyStreet: z.string().trim().max(...getMaxlengthMessageArgs()).optional(),
  companyUnit: z.string().trim().max(...getMaxlengthMessageArgs()).optional(),
  companyCity: z.string().trim().max(...getMaxlengthMessageArgs()).optional(),
  companyState: z.string().trim().max(...getMaxlengthMessageArgs()).optional(),
  companyZipCode: z.string().trim().max(...getMaxlengthMessageArgs()).optional(),
  companyCountry: z.string().trim().max(...getMaxlengthMessageArgs()).optional(),
  companyPhone: z.string().trim().max(...getMaxlengthMessageArgs(25)).optional()
}).refine(data => {
  if (data.companyID === 'new') {
    return data.companyName.length > 0;
  }
  return true;
}, {
  message: 'Company name is required',
  path: ['companyName']
});
export type FormData = z.infer<typeof formSchema>;
type AddCustomerFormProps = {
  onSubmit: (data: FormData) => void;
  onCancel: () => void;
  sameDomainCompanies?: ContactFindOneByEmailResponse['sameDomainCompanies'];
  enrichment?: ContactFindOneByEmailResponse['enrichment'];
  email: string;
  isLoading: boolean;
};
export const AddCustomerAndCompanyForm = ({
  onSubmit,
  onCancel,
  sameDomainCompanies,
  enrichment,
  email,
  isLoading
}: AddCustomerFormProps) => {
  const defaultValues = {
    firstName: '',
    lastName: '',
    email,
    phone: '',
    companyID: sameDomainCompanies?.length ? String(sameDomainCompanies[0].id) : 'new',
    companyName: '',
    companyStreet: '',
    companyUnit: '',
    companyCity: '',
    companyState: '',
    companyZipCode: '',
    companyCountry: '',
    companyPhone: '',
    ...enrichment
  };
  const methods = useForm<FormData>({
    resolver: zodResolver(formSchema),
    defaultValues
  });
  return <FormProvider {...methods} data-sentry-element="FormProvider" data-sentry-component="AddCustomerAndCompanyForm" data-sentry-source-file="AddCustomerAndCompanyForm.tsx">
      <Box component="form" onSubmit={methods.handleSubmit(onSubmit)} noValidate sx={{
      mt: 3,
      px: 3
    }} data-sentry-element="Box" data-sentry-source-file="AddCustomerAndCompanyForm.tsx">
        <Grid container spacing={2} data-sentry-element="Grid" data-sentry-source-file="AddCustomerAndCompanyForm.tsx">
          <Grid xs={12} lg={6} data-sentry-element="Grid" data-sentry-source-file="AddCustomerAndCompanyForm.tsx">
            <CustomerDetailsForm enrichment={enrichment} data-sentry-element="CustomerDetailsForm" data-sentry-source-file="AddCustomerAndCompanyForm.tsx" />
          </Grid>
          <Grid xs={12} lg={6} data-sentry-element="Grid" data-sentry-source-file="AddCustomerAndCompanyForm.tsx">
            <CompanyDetailsForm sameDomainCompanies={sameDomainCompanies} data-sentry-element="CompanyDetailsForm" data-sentry-source-file="AddCustomerAndCompanyForm.tsx" />
          </Grid>
        </Grid>
        <Box sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        mt: 3
      }} data-sentry-element="Box" data-sentry-source-file="AddCustomerAndCompanyForm.tsx">
          <Button onClick={onCancel} sx={{
          mr: 1,
          width: 236
        }} data-sentry-element="Button" data-sentry-source-file="AddCustomerAndCompanyForm.tsx">
            Cancel
          </Button>
          <LoadingButton type="submit" variant="contained" color="primary" sx={{
          mr: 1,
          width: 236
        }} loading={isLoading} data-sentry-element="LoadingButton" data-sentry-source-file="AddCustomerAndCompanyForm.tsx">
            Create new Customer
          </LoadingButton>
        </Box>
      </Box>
    </FormProvider>;
};