import React from 'react';
import { Container, ContainerProps, SxProps } from '@mui/material';
type Props = {
  children: React.ReactNode;
  maxWidth?: ContainerProps['maxWidth'];
  sx?: SxProps;
};
export const PageContainer = ({
  sx = {},
  maxWidth = 'lg',
  children
}: Props) => <Container maxWidth={maxWidth}
// TODO: The content min-height is being set in the layout. is it necessary to set it here?
sx={{
  minHeight: '90vh',
  position: 'relative',
  ...sx
}} data-sentry-element="Container" data-sentry-component="PageContainer" data-sentry-source-file="PageContainer.tsx">
    {children}
  </Container>;