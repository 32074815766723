import { useFormContext } from 'react-hook-form';
import { TextField, InputAdornment } from '@mui/material';
import { FormData } from './AddCustomerAndCompanyForm';
import { ReactComponent as MagicCircleIcon } from './assets/magic-circle-icon.svg';
type FormFieldProps = {
  name: keyof FormData;
  label: string;
  fullWidth?: boolean;
  enriched?: boolean;
  disabled?: boolean;
};
export const FormField = ({
  name,
  label,
  fullWidth = true,
  disabled = false,
  enriched = false
}: FormFieldProps) => {
  const {
    register,
    formState: {
      errors
    }
  } = useFormContext<FormData>();
  const enrichedSX = {
    '& .MuiInputBase-root': {
      backgroundColor: 'common.backgroundColor'
    }
  };
  return <TextField {...register(name)} fullWidth={fullWidth} label={label} error={!!errors[name]} helperText={errors[name]?.message} margin="normal" disabled={disabled} sx={enriched ? enrichedSX : undefined} InputProps={{
    endAdornment: enriched ? <InputAdornment position="end">
            <MagicCircleIcon />
          </InputAdornment> : undefined
  }} data-sentry-element="TextField" data-sentry-component="FormField" data-sentry-source-file="FormField.tsx" />;
};