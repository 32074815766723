import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import StarsIcon from '@mui/icons-material/Stars';
import { Box, SxProps, Typography } from '@mui/material';
import { EnrichmentSource } from '@/shared/TSConstants';
const VerifiedSourceIcon = () => <CheckCircleIcon sx={{
  fontSize: 12
}} color="success" data-sentry-element="CheckCircleIcon" data-sentry-component="VerifiedSourceIcon" data-sentry-source-file="CustomerEnrichmentLine.tsx" />;
const ExternalSourceIcon = () => <StarsIcon sx={{
  fontSize: 12
}} color="secondary" data-sentry-element="StarsIcon" data-sentry-component="ExternalSourceIcon" data-sentry-source-file="CustomerEnrichmentLine.tsx" />;
type CustomerEnrichmentLineProps = {
  source?: EnrichmentSource;
  text?: React.ReactNode | null;
  isBold?: boolean;
  typographySx?: SxProps;
  sx?: SxProps;
};
export const CustomerEnrichmentLine = ({
  source,
  text,
  isBold = false,
  typographySx = {},
  sx = {}
}: CustomerEnrichmentLineProps) => {
  if (!text) {
    return null;
  }
  const icon = source === 'user' ? <VerifiedSourceIcon /> : <ExternalSourceIcon />;
  return <Box sx={{
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    mb: 1,
    ...sx
  }} data-sentry-element="Box" data-sentry-component="CustomerEnrichmentLine" data-sentry-source-file="CustomerEnrichmentLine.tsx">
      {icon}
      <Typography variant={isBold ? 'h6' : 'bodySmall'} component="p" sx={typographySx} data-sentry-element="Typography" data-sentry-source-file="CustomerEnrichmentLine.tsx">
        {text}
      </Typography>
    </Box>;
};