import { Dispatch, SetStateAction } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { Typography, Unstable_Grid2 as Grid, Button } from '@mui/material';
import { ContactAutocompleteResponse } from '@/shared/types/controllers/spa/SPASearchControllerTypes';
import { ContactAutocomplete } from '@/src/components/Common/Autocomplete/ContactAutocomplete';
import { CreateNewCustomerDrawer } from '@/src/components/CreateNewCustomerDrawer/CreateNewCustomerDrawer';
import { useRightDrawerContext } from '@/src/contexts/useRightDrawerContext';
type CustomerDetailsProps = {
  selectedContact: ContactAutocompleteResponse['contacts'][0] | null;
  setSelectedContact: Dispatch<SetStateAction<ContactAutocompleteResponse['contacts'][0] | null>>;
};
export const CustomerDetails = ({
  selectedContact,
  setSelectedContact
}: CustomerDetailsProps) => {
  const {
    setActiveDrawer
  } = useRightDrawerContext();
  const openAddNewCustomerDrawer = (initialEmail?: string) => {
    setActiveDrawer(<CreateNewCustomerDrawer returnToCreateQuote initialEmail={initialEmail} />);
  };
  return <Grid xs={12} lg={6} data-sentry-element="Grid" data-sentry-component="CustomerDetails" data-sentry-source-file="CustomerDetails.tsx">
      <Typography variant="h4" mb={2} data-sentry-element="Typography" data-sentry-source-file="CustomerDetails.tsx">
        Search for a customer
      </Typography>
      <ContactAutocomplete selectedContact={selectedContact} setSelectedContact={setSelectedContact} openAddNewCustomerDrawer={openAddNewCustomerDrawer} data-sentry-element="ContactAutocomplete" data-sentry-source-file="CustomerDetails.tsx" />
      <Button variant="text" onClick={() => openAddNewCustomerDrawer()} sx={{
      mt: 2
    }} startIcon={<AddIcon />} data-sentry-element="Button" data-sentry-source-file="CustomerDetails.tsx">
        Add new customer
      </Button>
    </Grid>;
};