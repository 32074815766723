import { PropsWithChildren, ReactNode } from 'react';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Breadcrumbs, Stack, Button, ButtonProps } from '@mui/material';
type BreadcrumbButtonProps = ButtonProps & {
  isActive?: boolean;
};
export const BreadcrumbButton = ({
  isActive = false,
  ...restProps
}: BreadcrumbButtonProps) => <Button sx={{
  color: isActive ? 'primary.main' : 'text.primary'
}} variant="text" {...restProps} data-sentry-element="Button" data-sentry-component="BreadcrumbButton" data-sentry-source-file="StandardBreadcrumb.tsx" />;
type StandardBreadcrumbProps = PropsWithChildren<{
  endButton?: ReactNode;
}>;
export const StandardBreadcrumb = ({
  children,
  endButton
}: StandardBreadcrumbProps) => {
  return <Stack alignItems="center" justifyContent="end" direction="row" gap={3} sx={{
    width: '100%'
  }} data-sentry-element="Stack" data-sentry-component="StandardBreadcrumb" data-sentry-source-file="StandardBreadcrumb.tsx">
      <Breadcrumbs separator={<NavigateNextIcon sx={{
      mx: 1,
      color: 'tertiary.30p'
    }} fontSize="small" />} data-sentry-element="Breadcrumbs" data-sentry-source-file="StandardBreadcrumb.tsx">
        {children}
      </Breadcrumbs>
      {endButton}
    </Stack>;
};